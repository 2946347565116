/*
 ** PLAYER FUNCTIONS
*/

import { initializeFavoriteButton } from '../favorite-button/favorite-button';
import { getMedia } from '../../js/media-4.11';
import templates from '../../js/templates-4.4';

// Add volume slider
function addVolumeSlider() {
  const slider = document.getElementById('radio-slider');
  changeVolume(localStorage.getItem('volume'));
  slider.addEventListener('input', (e) => {
    changeVolume(e.target.value, true);
  });
}

// Change volume
function changeVolume(vol, isUserInteraction) {
  const volume = isNumeric(parseInt(vol)) ? parseInt(vol) : 100;
  const slider = document.getElementById('radio-slider');

  // Apply to slider
  slider.value = volume;

  // Mute
  if (volume === 0) {
    // Get last volume
    let volume_unmute = getVolume('volume', false);

    // Prevent 0 as last volume
    volume_unmute = volume_unmute > 0 ? volume_unmute : bowser.ios ? 100 : 50;

    // Save last volume as unmute volume
    localStorage.setItem('volume_unmute', volume_unmute);

    // Set speaker icon off and mute
    if (!audioPlayer.muted) {
      setButtonMute();

      // Use mute on iOS because the volume property is not settable in JavaScript
      bowser.ios && bowser.osversion.split('.')[0] < 10
        ? audioPlayer.pause()
        : audioPlayer.muted = true;
    }
  } else {
    // Set unmute button.
    setButtonUnMute();

    // Is the volume changed by a user interaction?
    if (isUserInteraction) {
      setButtonStop();
    }

    // Play/Unmute the stream.
    if (audioPlayer.paused) {
      playWithPromise();
    }

    if (audioPlayer.muted) {
      audioPlayer.muted = false;
    }
  }

  // Save new volume value
  localStorage.setItem('volume', volume);

  // Apply new volume
  audioPlayer.volume = volume / 100;

  return volume;
}

// Get player
export function getPlayer(station_name, set_station_data) {
  const url = webradioConfig.urlApi + 'data/streams/' + webradioConfig.domainID + '/' + station_name;

  // Load JSON-encoded station data
  fetch(url)
    .then((res) => res.json())
    .then((data) => onStreamDataSuccess(data, set_station_data))
    .catch(onStreamDataError);
}

function onStreamDataSuccess(data, set_station_data) {
  // Station data unsuccessfully returned?
  if (
    typeof data === 'undefined' || Object.keys(data).length === 0 ||
    typeof data.success === 'undefined' || data.success !== true
  ) {
    return onStreamDataError();
  } else {
    // Create a new audio object and store it in the window object.
    window.audioPlayer = new Audio();
    data = data.result;
  }

  // Set the station data dynamically
  if (set_station_data) {
    const logo = document.getElementById('radio-logo');
    const title = document.getElementById('radio-title');
    const signal = document.getElementById('radio-signal');
    const link = document.getElementById('radio-link');
    const track = document.getElementById('radio-track');

    // Set the station logo
    logo.setAttribute('src', webradioConfig.urlStatic + 'images/logos/' + webradioConfig.slugDomain + '/' + data.station.name + '.png');
    logo.setAttribute('alt', data.station.title);

    // Set the station title and signal
    title.innerText = data.station.title;
    signal.innerText = data.station.signal;

    // Set the station link
    if (data.station.url) {
      link.setAttribute('href', data.station.url);
      link.style.display = 'inline-block';
    } else {
      link.setAttribute('href', '#');
      link.style.display = 'none';
    }

    // Fade opacity out and remove loading class from player track
    track.classList.remove('loading');

    setMediaSessionMetadata(data.station.title, data.station.signal);
  }

  let media = getMedia();

  // Overrule device specific settings
  if (data.station.usePopup === true) {
    media = { HTML: [true, ''] };
  }

  // Map the stream types to an array
  const streamTypes = Object.keys(media).reduce((out, key) => {
    media[key].forEach((isContainer) => {
      if (isContainer !== '') {
        out.push(key + '|' + isContainer.toString());
      }
    });
    return out;
  }, []);

  // Filter the streams by media type and is container
  data.streams = data.streams.filter((stream) => {
    return stream.mediaType in media && media[stream.mediaType].includes(stream.isContainer);
  });

  // iOS 11+ doesn't support playing .pls (audio/x-scpls) streams so filter them out
  if (bowser.ios && bowser.osversion.split('.')[0] >= 11) {
    data.streams = data.streams.filter((stream) => {
      return !(stream.isContainer === true && stream.url.includes('.pls'));
    });
  }

  // Sort the filtered streams by stream type
  data.streams.sort((a, b) => {
    return streamTypes.findIndex((streamType) => streamType === a.mediaType + '|' + a.isContainer) < streamTypes.findIndex((streamType) => streamType === b.mediaType + '|' + b.isContainer) ? -1 : 1;
  });

  // Get the first stream from the sorted streams
  data.stream = data.streams[0];

  // Define the stream player
  data.stream === undefined ? data.stream = [] :
    (data.station.usePopup === true || (data.stream.mediaType === 'HTML' && location.protocol === 'https:' && new RegExp( '^http:' ).test( data.stream.url )) ? data.stream.usePopup = true :
        (data.stream.mediaType === 'HTML' ? data.stream.useIframe = true : data.stream.usePlayer = true)
    );

  // Chrome does not support playing insecure streams over secure pages
  // NOTE: iOS excluded since browsers on iOS must use the WebKit rendering engine
  if (bowser.chrome && !bowser.ios && data.stream.usePlayer === true && location.protocol === 'https:' && new RegExp('^http:').test(data.stream.url)) {
    // Try to find a secure stream
    const secure_stream = data.streams.find((stream) => {
      return stream.mediaType !== 'HTML' && new RegExp('^https:').test(stream.url);
    });

    // Play using secure stream or fallback to player popup
    if (typeof secure_stream !== 'undefined') {
      data.stream = secure_stream;
      data.stream.usePlayer = true;
    } else {
      delete data.stream.usePlayer;
      data.stream.usePlayerPopup = true;
    }
  }

  // Merge station data with config
  webradioConfig = Object.assign({}, webradioConfig, data);

  // Set the player
  const radioControls = document.getElementById('radio-controls');

  // Render the player template
  radioControls.innerHTML = templates['player'].render(webradioConfig);

  // Fade opacity out and remove loading class from player controls
  radioControls.classList.remove('loading');

  // Play stream with player or in a popup?
  if ('usePlayer' in webradioConfig.stream) {
    setControls();
    playStream({
      streamMime: webradioConfig.stream.mime,
      streamUrl: webradioConfig.stream.url
    });
  } else if ('usePlayerPopup' in webradioConfig.stream) {
    const popupLinks = radioControls.querySelectorAll('.link-external');
    Array.prototype.forEach.call(popupLinks, (link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        openPlayerPopup('http://' + window.location.hostname + '/play/' + webradioConfig.station.name + '/' );
      });
    });
  }

  if (webradioConfig.station.acrCloud) {
    const element = document.querySelector('.footer__acr');

    if (element) {
      element.classList.add('footer__acr--show')
    }
  }

  initializeFavoriteButton({
    station_id: data.station.id,
    station_name: data.station.name,
    station_title: data.station.title,
  });

  saveToRecentlyPlayed({
    station_id: data.station.id,
    station_name: data.station.name,
    station_title: data.station.title,
  });
}

function onStreamDataError() {
  // Delete the audioPlayer from the window object.
  delete window.audioPlayer;

  // Redirect to page without the hash, otherwise to home
  if (window.location.hash) {
    window.location.href = window.location.href.split('#').shift();
  } else if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
}

// Get volume
function getVolume(key, normalized) {
  let volume = 100;

  // Get volume value
  volume = localStorage.getItem(key);

  // Volume value must be an integer
  volume = parseInt(volume);

  // Validate volume value
  volume = isNumeric(volume) && volume >= 0 && volume <= 100 ? volume : 100;

  return normalized ? volume / 100 : volume;
}

// Open player popup
function openPlayerPopup(url, width, height) {
  const popupWidth = width || 492;
  const popupHeight = height || 164;
  const popupParams = ',menubar=no,toolbar=no,location=yes,status=no,resizable=no,scrollbars=no';

  const popupWindow = window.open(url, 'webradio', 'width=' + popupWidth + ',height=' + popupHeight + popupParams);
  popupWindow !== null && typeof popupWindow.focus === 'function' && popupWindow.focus();
}

function playStream(config) {
  let onloadRetry = 0;

  const codec_extensions = {
    'application/x-mpegURL': 'm3u8',
    'application/dash+xml':'mpd'
  };

  audioPlayer.setAttribute('src', config.streamUrl)
  audioPlayer.setAttribute('type', config.streamMime)
  audioPlayer.setAttribute('title', webradioConfig.station.title)
  audioPlayer.setAttribute('autoplay', config.streamMime in codec_extensions ? false : true);
  audioPlayer.volume = getVolume('volume', true);

  // Set stop button on full buffer
  audioPlayer.addEventListener('playing', (e) => {
    setButtonStop();
    onloadRetry = 0;
  });

  // Set play button on pause
  audioPlayer.addEventListener('pause', setButtonPlay);

  // Set error button when network connection was lost or any unknown data error occurs
  audioPlayer.addEventListener('stalled', (e) => {
    // Validate network and readiness state of the media before setting error button
    if (
      (audioPlayer.networkState === null || audioPlayer.networkState !== 2) &&
      (audioPlayer.readyState === null || audioPlayer.readyState > 0)
    ) {
      setButtonError('Error code 0 ' + '(Failed to play stream because of data is unexpectedly not forthcoming)');
    }
  });

  audioPlayer.addEventListener('error', (e) => {
    const errorCode = e.target.error.code;

    if (errorCode === 4 && config.streamMime in codec_extensions) {
      return;
    }

    // Retry to load stream 3x if the media source is supported
    if (errorCode !== 4 && onloadRetry !== 3) {
      setTimeout(() => {
        onloadRetry++;
        audioPlayer.load();
      }, 500)
    }

    // Load failed, stream could be dead or max users reached
    else {
      let description = '';

      switch (errorCode) {
        case 1:
          description = 'Stream playback aborted';
          break;
        case 2:
          description = 'Failed to download stream';
          break;
        case 3:
          description = 'Failed to decode stream';
          break;
        case 4:
          description =
            'Failed to load stream because of a server error or an unsupported format';
          break;
        default:
          description = 'Error code ' + errorCode + ' (' + description + ')';
      }

      setButtonError(description);
    }
  });

  // Check if a Media Source Extension is required to play this sound object
  if (config.streamMime in codec_extensions) {
    // Check browser support for Media Source Extensions
    if (typeof hasMSE === 'boolean' && hasMSE) {
      // Play HLS sounds using the hls.js Media Source Extension
      if (config.streamMime === 'application/x-mpegURL') {
        const hlsUrl = 'https://cdnjs.cloudflare.com/ajax/libs/hls.js/' + webradioConfig.hlsJs.version + '/hls.min.js';
        getScript(hlsUrl, () => {
          // Instantiate hls.js MediaSource object
          const hls = new Hls({
            backBufferLength: 90,
          });

          // Create MediaSource object
          hls.attachMedia(audioPlayer);

          // Set source URL when the MediaSource object is successfully created
          hls.on(Hls.Events['MEDIA_ATTACHED'], function () {
            hls.loadSource(config.streamUrl);
          });

          // Play when manifest has been parsed
          hls.on(Hls.Events['MANIFEST_PARSED'], function () {
            // Play audio with promise
            playWithPromise();
          });

          // hls.js error handling
          hls.on(Hls.Events['ERROR'], function (event, data) {
            // Catch fatal errors
            if (data.fatal) {
              hls.destroy();
              setButtonError('Fatal error in hls.js');
            }
          });
        }, () => setButtonError('Failed to load hls.js'));
      }

      // Play MPEG-DASH sounds using the dash.js Media Source Extension
      else if (config.streamMime === 'application/dash+xml') {
        const dashUrl = 'https://cdnjs.cloudflare.com/ajax/libs/dashjs/' + webradioConfig.dashJs.version + '/legacy/umd/dash.mediaplayer.min.js';
        getScript(dashUrl, () => {
          // Instantiate dash.js MediaSource object
          const dash = dashjs.MediaPlayer().create();

          // Create MediaSource object and set source URL
          dash.initialize(audioPlayer, config.streamUrl, true);

          dash.updateSettings({
            'streaming': {
              'delay': {
                'liveDelay': 10
              }
            }
          });

          // dash.js error handling
          dash.on(dashjs.MediaPlayer.events['ERROR'], function (e) {
            // Ignore failed time synchronization
            if (dashjs.MediaPlayer.errors['TIME_SYNC_FAILED_ERROR_CODE'] !== e.error.code) {
              dash.reset();
              setButtonError('Fatal error in dash.js');
            }
          });

          // Play when stream is being initalized
          dash.on(dashjs.MediaPlayer.events['STREAM_INITIALIZING'], function () {
            playWithPromise();
          });
        }, () => setButtonError('Failed to load dash.js'));
      }
    } else {
      setButtonError('No Media Source Extensions support');
    }
  }

  playWithPromise()
  addVolumeSlider()
}

// Play audio with promise
function playWithPromise() {

  // Get promise for play event on the HTMLAudioElement
  const promise = audioPlayer.play();

  // Use promise to track auto-play policy if supported
  if (promise !== undefined) {
    promise.catch((reason) => {
      // Catch auto-play is not allowed error
      if (reason.toString().lastIndexOf('NotAllowedError', 0) === 0) {
        // Set play button to require the user gesture click
        setButtonPlay();
      }
    });

    // Check if auto-play is set on the HTMLAudioElement
  } else if (audioPlayer.getAttribute('autoplay') !== 'true') {
    // Setting auto-play on the HTMLAudioElement is required for the Android Samsung and stock browser
    audioPlayer.setAttribute('autoplay', true);

    // Catch iOS never supports auto-play, catch if promises are not supported
    if (bowser.ios) {
      // Set play button to require the user gesture click
      setButtonPlay();
    }
  }
}

// Mute button
function setButtonMute() {
  const soundButton = document.getElementById('radio-sound');
  soundButton.setAttribute('src', webradioConfig.urlStatic + 'images/icon-sound-off.png');
  soundButton.dataset.state = 'off';
}

// Unmute button
function setButtonUnMute() {
  const soundButton = document.getElementById('radio-sound');
  soundButton.src = webradioConfig.urlStatic + 'images/icon-sound-on.png';
  soundButton.dataset.state = 'on';
}

// Set player button
function setPlayerButton(button_name, button_ext, button_title) {
  const oldButton = document.querySelector('#radio-button');
  const playerButtonNew = document.createElement('img');

  playerButtonNew.id = 'radio-button';
  playerButtonNew.alt = webradioConfig.txt[button_name];
  playerButtonNew.classList.add(button_name);
  playerButtonNew.src = webradioConfig.urlStatic + 'images/icon-' + button_name + '.' + (typeof button_ext === 'string' ? button_ext : 'png');
  playerButtonNew.setAttribute('title', typeof button_title === 'string' ? button_title : '');

  // Only update the button if the state has changed.
  if (!playerButtonNew.isEqualNode(oldButton)) {
    oldButton.parentNode.appendChild(playerButtonNew);
    oldButton.remove();
  }
}

// Error button
function setButtonError(error_text) {
  setPlayerButton('error', 'png', typeof error_text === 'string' ? error_text : '');

  // Track error event in Analytics
  ga('send', 'event', 'Player', 'Error');
  gtag("event", "exception",{
    "description": "Player error",
    "fatal": false
  });
}

// Load button
function setButtonLoad() {
  setPlayerButton('load', 'gif');
}

// Play button
function setButtonPlay() {
  setPlayerButton('play');
}

// Stop button
function setButtonStop() {
  setPlayerButton('stop');
}

// Set the play and mute controls
function setControls() {
  document.querySelector('#radio-controls .btn-play').addEventListener('click', (e) => {
    e.preventDefault();

    // Switch based on current button state
    switch (document.getElementById('radio-button').getAttribute('alt')) {
      case webradioConfig.txt.play:
        setButtonStop();

        if (audioPlayer.muted) {
          audioPlayer.muted = false;
        } else if (audioPlayer.paused) {
          setButtonLoad();
          playWithPromise();
        }

        break;
      case webradioConfig.txt.start:
        setButtonLoad();
        break;
      case webradioConfig.txt.stop:
        setButtonPlay();

        // Mute the sound object
        bowser.ios && bowser.osversion.split('.')[0] < 10
          ? audioPlayer.pause()
          : audioPlayer.muted = true;
        break;
    }
  });

  // Set the mute controls
  document.querySelector('#radio-controls .ico-sound').addEventListener('click', (e) => {
    e.preventDefault();

    const slider = document.getElementById('radio-slider');
    let volume = 0;

    if (document.getElementById('radio-sound').dataset.state === 'on' && slider.value > 0) {
      volume = 0;
    } else {
      volume = localStorage.getItem('volume_unmute');
    }

    changeVolume(volume);
  });
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function getScript(source, successCallback, errorCallback) {
  let script = document.createElement('script');
  const prior = document.getElementsByTagName('script')[0];
  script.async = 1;

  script.onerror = errorCallback;
  script.onload = script.onreadystatechange = function (_, isAbort) {
    if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
      script.onload = script.onreadystatechange = null;
      script = undefined;

      if (!isAbort && successCallback) setTimeout(successCallback, 0);
    }
  };

  script.src = source;
  prior.parentNode.insertBefore(script, prior);
}

function saveToRecentlyPlayed(stationData) {
  const stations = JSON.parse(localStorage.getItem('recentlyPlayed')) || [];

  // check if already in recently played
  const existingStationIndex = stations.findIndex((station) => station.station_id === stationData.station_id);

  if (existingStationIndex > -1) {
    stations.splice(existingStationIndex, 1);
  }

  stations.unshift(stationData);
  localStorage.setItem('recentlyPlayed', JSON.stringify(stations));
}

function setMediaSessionMetadata(stationTitle, stationSignal) {
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: stationTitle,
      artist: stationSignal,
    });
  }
}
