import {emitEvent} from '../event-emitter/event-emitter';

var $searchInputHead = document.getElementById("search-input-head");
var $searchInputMenu = document.getElementById("search-input-menu");

// Get random Algolia API/KEY
webradioConfig.algolia = randomElement(webradioConfig.algolia);

// Initialize Algolia client
var client = window["algoliasearch/lite"].liteClient(
  webradioConfig.algolia.applicationId,
  webradioConfig.algolia.apiKey
);

// Trigger search callback for head
if ($searchInputHead) {
  $searchInputHead.addEventListener("input", function (e) {

    // Search using input query
    searchQuery(e.target.value);

    // Sync query with menu input field
    $searchInputMenu.value = e.target.value;
  });

  $searchInputHead.closest("form").addEventListener('submit', function (e) {
    e.preventDefault();

    // Remove the keyboard on mobile
    $searchInputHead.blur();

    // Enable Site Search Tracking in Google Analytics
    ga("send", "pageview", "/?q=" + encodeURIComponent($searchInputHead.value));
    gtag("event", "search", {
      search_term: encodeURIComponent($searchInputHead.value)
    });
  });

  // Restore search results when input value is set
  if ($searchInputHead.value.length) {
    var rs = "";

    rs = sessionStorage.getItem("search-results");

    // Use search results from session storage otherwise trigger input field to query again
    if (rs) {
      document.getElementById("search-results").innerText = rs;

      // Hide content area
      document.getElementById('content-area').style.display = 'none';
    } else {
      // Trigger input field to query again
      emitEvent('trigger', $searchInputHead);
    }
  }
}

// Trigger search callback for menu
if ($searchInputMenu) {
  $searchInputMenu.closest("form").addEventListener('submit', function (e) {
    e.preventDefault();

    // Search using input query
    searchQuery($searchInputMenu.value)

    // Sync query with head input field
    $searchInputHead.value = $searchInputMenu.value;

    // Close the menu on on mobile
    document.querySelector('#content .menu-opener').click();

    // Remove the keyboard on mobile
    $searchInputMenu.blur();
  });
}

// Returns random element from array
function randomElement(array) {
  return array[Math.floor(array.length * Math.random())];
}

// Algolia search query
function searchQuery(query) {

  // Ignore query?
  if (["59063"].indexOf(query) > -1) {
    return;
  }

  // Empty query?
  if (!query) {

    // Remove search results
    document.getElementById('search-results').innerHTML = null;

    // Show content area
    document.getElementById('content-area').style.display = 'block';

    return;
  }

  // Search callback
  client.search([{
    indexName: "webradio",
    params: {
      query: query,
      facetFilters: ["country_code: " + webradioConfig.countryCode],
      hitsPerPage: 42
    }
  }]).then(function(response) {
    searchCallback({
      hits: response.results[0].hits,
      query: query
    });
  }, function(e) {
    console.error(e);

    return false;
  });
}

// Algolia search callback
function searchCallback(data) {

  // Loop results
  let rs = data.hits.map(station => {
    return `<li><span><a href="${station.url}" title="${station.title}"><img class="cover" src="${station.image}" alt="${station.title}" height="66" width="96"></a></span></li>`
  }).join('');

  // Set empty results
  for (var i = data.hits.length; i < 42; i++) {
    rs += `<li class="hidden"><span><img class="img" src="${webradioConfig.urlStatic}images/radio.png" alt="" height="66" width="96"></span></li>`;
  }

  // Wrap results in block, showing the query string html escaped in the header
  rs = `<div class="radio-section"><h1>${webradioConfig.txt[data.hits.length === 0 ? "search_none" : "search_for"].replace("%s", `<div>${data.query}`)}</h1><ul id="radios">${rs}"</ul></div>`;

  // Set results
  document.getElementById('search-results').innerHTML = rs;

  // Hide content area
  document.getElementById('content-area').style.display = 'none';

  // Save results
  sessionStorage.setItem("search-results", rs);
}
